export const RETRIEVE_ERRORS = {
  DOWNSTREAM_ERROR: 'DOWNSTREAM_ERROR',
  NOT_FOUND_ERROR: 'NOT_FOUND_ERROR'
}

export const ERROR_MESSAGE_DOWNSTREAM_ERROR = "We're unable to track your order at this time. Please try again later.";
export const ERROR_CARD_HEADER_DOWNSTREAM_ERROR = "Something went wrong."
export const ERROR_CARD_HEADER_NOT_FOUND_ERROR = "Tracking isn't available yet."
export const ERROR_MESSAGE_NOT_FOUND_ERROR = "If you recently placed an order, check back in a few minutes.";
export const ERROR_MESSAGE_INCORRECT_ORDER_ID = "Sorry, the format of the order number you have entered is not valid. Please check your order number and try again."
